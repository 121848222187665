import { Fragment, useEffect, useState } from "react";
import { route } from "../../../globals/constants";
import FastImage from "../../../globals/elements/fastimg";
import { Link, NavLink, useParams } from "react-router-dom";
import { getProducts } from "../../../api/productsApi";
import { useTranslation } from "react-i18next";
import SectionBanner from "../../sections/common/banner/banner";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../../store/cartSlice";
import Loader from "../Loader";
import FilterProducts from "../FilterProducts";
import { servicesFetch } from "../../../store/servicesSlice";
import { CircularProgress } from "@mui/material";

function ShopProductsPage() {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("false");
  const [products, setProducts] = useState([]);
  const { lang } = useParams();
  const locale = lang;
  const { t } = useTranslation();
  const [text, setText] = useState(
    lang === "en"
      ? "No result for such type of product"
      : "نأسف لعدم وجود منتجات من هذا النوع"
  );

  const dir = lang === "en" ? "ltr" : "ltr";

  const trips = useSelector((state) => state.services.services);

  useEffect(() => {
    dispatch(servicesFetch({ locale }));
    getProducts({ locale })
      .then((res) => {
        setLoaded(true);
        setProducts(res.data.products);
        setLoading(false);
      })
      .catch((err) => {
        console.log({ err });
        setLoading(false);
      });
  }, []);

  const updateProducts = (products) => {
    setProducts(products);
  };

  const handleType = (result) => {
    setType(result);
  };

  const handleAddToCart = (product) => {
    dispatch(addToCart({ product, qty: 1 }));
  };

  if (loading) {
    return <Loader />;
  }

  const generateProducts = () => {
    return trips?.map((trip) => {
      return (
        <div
          key={trip.id}
          // style={{ maxWidth: "25rem" }}
          className="col-lg-4 col-md-6 col-sm-6 m-b30"
        >
          <div className="mt-box mt-product-box bdr-5 bdr-solid border border-white shadow shaodw bg-white">
            <div className="mt-thum-bx mt-img-overlay4 mt-img-effect zoom">
              <FastImage
                proImage={trip.image && trip.image}
                src={trip.image}
                alt={trip.title}
                style={{ height: 350 }}
              />
            </div>
            <div className="mt-info  text-center">
              <div className="p-a10">
                <h4 title={trip.title.substring(0, 30)} className="mt-title">
                  {" "}
                  <Link to={`/${lang}/service/detail/${trip.id}`}>
                    {trip.title.substring(0, 30)}
                  </Link>
                </h4>
                <p
                  className="fs-6"
                  style={{ height: 80 }}
                  dangerouslySetInnerHTML={{
                    __html: trip.description.substring(0, 100) + "...",
                  }}
                />
                <div className="p-tb15">
                  <Link
                    className="site-button"
                    to={`/${lang}/service/detail/${trip.id}`}
                  >
                    {t("trip_detail")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const currentpath = {
    crumb: t("our_trips"),
    title: t("our_trips"),
  };

  return (
    <Fragment>
      <SectionBanner data={currentpath} />

      {/* Our Product */}
      {loaded ? (
        <div dir={dir} className="section-full p-t80">
          <div className="container-fluid">
            <div className="section-content">
              {/* TITLE START */}
              <div className="section-head text-center">
                <h2 data-title={"Trips"}>{t("our_trips")}</h2>
                <div className="mt-separator-outer m-b30">
                  <div className="mt-separator site-bg-primary" />
                </div>
              </div>
              {/* TITLE END */}
              <div className="row d-flex justify-content-center">
                <div className="col-sm-12 col-md-9 col-lg-9">
                  <div className="row">{generateProducts()}</div>
                  {type === "error" && <h3 dir={dir}>{text}</h3>}
                  {type === "loading" && <CircularProgress color="success" />}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </Fragment>
  );
}
export default ShopProductsPage;
