import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCompanies } from "../../api/companiesApi";

export const companiesFetch = createAsyncThunk("companies/fetch", getCompanies);

export const initialState = {
  value: [],
  isLoading: false,
  loaded: false,
  error: null,
};

export const companiesSlice = createSlice({
	name: 'companies',
	initialState,
	extraReducers: builder => {
		builder.addCase(companiesFetch.pending, state => {
			state.isLoading = true;
		});
		
		builder.addCase(companiesFetch.fulfilled, (state, action) => {
			state.isLoading = false;
			state.loaded = true;
			state.value = action.payload.data;
		});
		
		builder.addCase(companiesFetch.rejected, (state, action) => {
			state.isLoading = false;
			state.loaded = false;
			state.error = action.payload;
		});		
	}
});


const companiesReducer = companiesSlice.reducer;
export default companiesReducer;
