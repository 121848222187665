import React from "react";
import { Link } from "react-router-dom";
import { t } from "i18next";
import FastImage from "../../../../globals/elements/fastimg";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

//CSS IMPORT
import "./card.css";

function SectionHome1Trips({ trips }) {
  const generateTrips = () => {
    return trips?.map((trip) => {
      return (
        <div
          key={trip.id}
          // style={{ maxWidth: "25rem" }}
          className="col-lg-4 col-md-6 col-sm-6 m-b30"
        >
          <div className="mt-box mt-product-box bdr-5 bdr-solid border border-white shadow shaodw bg-white">
            <div className="mt-thum-bx mt-img-overlay4 mt-img-effect zoom">
              <FastImage
                proImage={trip.image && trip.image}
                src={trip.image}
                alt={trip.title}
                style={{ height: 350 }}
              />
            </div>
            <div className="mt-info  text-center">
              <div className="p-a10">
                <h4 title={trip.title.substring(0, 30)} className="mt-title">
                  {" "}
                  <Link to={`/${lang}/service/detail/${trip.id}`}>
                    {trip.title.substring(0, 30)}
                  </Link>
                </h4>
                <p
                  className="fs-6"
                  style={{ height: 80 }}
                  dangerouslySetInnerHTML={{
                    __html: trip.description.substring(0, 100) + "...",
                  }}
                />
                <div className="p-tb15">
                  <Link
                    className="site-button"
                    to={`/${lang}/service/detail/${trip.id}`}
                  >
                    {t("trip_detail")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };
  const { lang } = useParams();
  return (
    <div className="section-full p-t80">
      <div className="container-fluid background-image">
        <div className="section-content">
          {/* TITLE START */}
          <div className="section-head text-center text-white">
            <h2 className="">{t("our_trips")}</h2>
            <div className="mt-separator-outer m-b30">
              <div className="mt-separator site-bg-primary" />
            </div>
          </div>
          {/* TITLE END */}
          <div className="row d-flex justify-content-center">
            <div className="col-sm-12 col-md-9 col-lg-9">
              <div className="row">{generateTrips()}</div>
              <div style={{ textAlign: "center" }}>
                <Link className="m-t30 site-button" to={`/${lang}/trips`}>
                  {t("show_trips")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SectionHome1Trips.propTypes = {
  trips: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

export default SectionHome1Trips;
