/* eslint-disable react/prop-types */
import { t } from "i18next";
import React, { Fragment, useEffect, useState } from "react";
import { orderPost } from "../../../api/ordersApi";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import AlertToast from "../Alert";
import { CircularProgress } from "@mui/material";
import Error from "../Error";
import { useDispatch } from "react-redux";
import { emptyCart } from "../../../store/cartSlice";

const OrderSection = ({ cart }) => {
  const dispatch = useDispatch();
  const { lang } = useParams();
  const locale = lang;
  const dir = lang === "en" ? "ltr" : "ltr";
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [text, setText] = useState("");
  const [country, setCountry] = useState("");
  const [values, setValues] = useState({
    email: "",
    name: "",
    address: "",
    message: "",
  });
  const [phone, setPhone] = useState("");

  useEffect(() => {
    // console.log({ country });
  }, []);

  const { email, name, address, message } = values;

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisabled(true);
    const data = { ...values, phone, ...cart };
    // console.log({ data });
    orderPost({ locale, values: data })
      .then((res) => {
        // console.log({ res: res.data });
        setText(lang === "en" ? "Order is successful!" : "تم الطلب بنجاح!");
        setOpen(true);
        setDisabled(false);
        setError(false);
        dispatch(emptyCart());
        setValues({ email: "", name: "", address: "", message: "" });
        setPhone("");
      })
      .catch((err) => {
        setError(true);
        setErrors(err.response.data.data);
        setDisabled(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
    setError(false);
  };

  // console.log({ phone });

  // const handlePhoneChange = (val) => {
  //   console.log({val})
  //   if (val !== undefined && val.length) {
  //     const formate = formatPhoneNumberIntl(val);
  //     setPhone(formate);
  //   }
  // };

  return (
    <Fragment>
      {error && (
        <Error error={errors} errorsShow={error} handleClose={handleClose} />
      )}
      <AlertToast open={open} message={text} handleClose={handleClose} />
      <Form className="" onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            name="name"
            type="text"
            value={name}
            onChange={handleChange}
            className="form-control"
            placeholder={t("name")}
          />
        </div>
        <div className="form-group">
          <input
            name="email"
            type="text"
            value={email}
            onChange={handleChange}
            className="form-control"
            placeholder={t("email")}
          />
        </div>
        <div className="form-group">
          <PhoneInput
            name="phone"
            country={lang === "ar" ? "sa" : "us"}
            value={phone}
            onChange={(phone) => setPhone(phone)}
            dir="ltr"
            inputClass="form-control"
            inputStyle={{
              borderColor: "#e1e6eb",
              boxShadow: "none",
              width: "100%",
              height: 40,
              fontSize: 13,
              lineHeight: 20,
              borderRadius: "6px !important",
              direction: lang === "en" ? "ltr" : "ltr",
              paddingInlineStart: lang === "ar" && 50,
            }}
          />
        </div>
        <div className="form-group">
          <input
            name="address"
            type="text"
            value={address}
            onChange={handleChange}
            className="form-control"
            placeholder={t("address")}
          />
        </div>
        <div className="form-group">
          <textarea
            name="message"
            className="form-control"
            rows={6}
            value={message}
            onChange={handleChange}
            placeholder={t("message")}
            defaultValue={""}
            // style={{ minHeight: 100 }}
          />
        </div>
        {!disabled ? (
          <button type="submit" className="site-button ">
            <span className="font-weight-700 inline-block  p-lr15">
              {t("submit")}
            </span>
          </button>
        ) : (
          <button disabled type="submit" className="site-button ">
            <CircularProgress color="success" />
          </button>
        )}
      </Form>
    </Fragment>
  );
};

export default OrderSection;
