function Loader() {
  return (
    <div className="loading-area">
      <div className="loading-box" />
      <div className="loading-pic">
        <div className="loading_progress-container">
          <div className="loading_progress">
            <div className="loading_progress-bar">
              <div className="loading_progress-shadow" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Loader;
