import React, { Fragment, useEffect } from 'react'
import SectionBanner from '../../../sections/common/banner/banner'
import { NavLink, useParams } from 'react-router-dom'
import { t } from 'i18next';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { JSONTree } from 'react-json-tree';
import { companyFetch } from '../../../../store/singleCompanySlice';
import Loader from '../../../../globals/elements/loader';
import FastImage from '../../../../globals/elements/fastimg';

const CompanyPage = () => {
  const dispatch = useDispatch();
  const {lang, companyId} = useParams();
  const locale = lang;
  const dir = lang === 'en' ? 'ltr' : 'rtl';

  const companyData = useSelector(state => state.company);

  const {isLoading, error, loaded, company, categories} = companyData;
  

  useEffect(() => {
    dispatch(companyFetch({locale, companyId}));
  }, [dispatch]);


  if(isLoading) {
    return <Loader/>
  }

  if(error) {
    return <h1>Error fetching data!</h1>
  }

  const displayCategories = () => {
    return loaded && categories.map(category => {
      return (
        <Col
            key={category.id}
            className="col-lg-4 col-md-6 col-xs-100pc m-b30"
          >
            <div
              style={{ height: 300, width: 300 }}
              className="item overflow-hide"
            >
              <div className="mt-box">
                <div
                  style={{ height: "100%" }}
                  className="mt-img-effect overlay-2"
                >
                  <FastImage
                    src={category.image}
                    proImage={category.image}
                    alt={category.title}
                    style={{ height: 300 }}
                  />
                  <div className="overlay-2-bg bg-black" />
                  <div className="overlay-2-content">
                    <div className="p-a30 p-b20 d-flex flex-column align-items-center justify-content-center">
                      <h4 className="m-t0 m-b15 site-text-primary">
                        {category.title}
                      </h4>
                      <div
                        className="m-b20 text-white"
                        style={{
                          fontSize: "0.7rem",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: category.description,
                        }}
                      />

                      <NavLink to={`/${lang}/companies/${companyId}/categories/${category.id}`} className="site-button">
                        {t('show_more')}
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
      )
    })
  }


  const currentpath = {
    crumb: loaded && company.title,
    title: loaded && company.title,
  };

  return (
    <Fragment>
      <SectionBanner data={currentpath} />
      {/* <JSONTree data={company} /> */}
      {/* TITLE START */}
      <div dir={dir} className="section-full p-t80">
        <div className="container">
          <div className="section-content"></div>
          <div className="section-head text-center">
            <h2 data-title={t("categories")}>{t("categories")}</h2>
            <div className="mt-separator-outer m-b30">
              <div className="mt-separator site-bg-primary" />
            </div>
          </div>
          {/* TITLE END */}
          <Row>{displayCategories()}</Row>
        </div>
      </div>
    </Fragment>
  )
}

export default CompanyPage