import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCategory } from "../../api/categoriesApi";

export const categoryFetch = createAsyncThunk("category/fetch", getCategory);

const initialState = {
  category: null,
  products: null,
  isLoading: false,
  loaded: false,
  error: null,
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(categoryFetch.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(categoryFetch.fulfilled, (state, action) => {
      state.isLoading = false;
      state.loaded = true;
      state.category = action.payload.data.category;
      state.products = action.payload.data.category.products;
    });
    builder.addCase(categoryFetch.rejected, (state, action) => {
      state.isLoading = false;
      state.loaded = false;
      state.error = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function

const categoryReducer = categorySlice.reducer;
export default categoryReducer;
