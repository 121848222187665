/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./gallery.css";

import { t } from "i18next";

function Gallery({ gallery, showAll }) {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [flattenedImages, setFlattenedImages] = useState([]);

  useEffect(() => {
    const imagesArray = gallery.flatMap((item) =>
      item.images.map((imageObj) => ({
        url: imageObj.url,
        title: item.title,
      }))
    );
    setFlattenedImages(imagesArray);

    const loadImages = async () => {
      const promises = imagesArray.map((imageObj) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = imageObj.url;
          img.onload = () => {
            resolve();
          };
          img.onerror = () => {
            console.error(`Failed to load image: ${imageObj.url}`);
            reject();
          };
        });
      });

      try {
        await Promise.all(promises);
        setImagesLoaded(true);
        console.log("All images loaded successfully");
      } catch (error) {
        console.error("Error loading images", error);
        setLoadingError(true);
      }
    };

    loadImages();
  }, [gallery]);

  const openLightbox = (index) => {
    if (imagesLoaded) {
      setPhotoIndex(index);
      setIsOpen(true);
    }
  };

  const imagesToDisplay = showAll
    ? flattenedImages
    : flattenedImages.slice(0, 6);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container">
      <div className="text-center">
        <h2 className="p-t30 text-black">{t("gallery")}</h2>
        <div className="mt-separator-outer">
          <div className="mt-separator site-bg-primary mb-5" />
        </div>
      </div>
      {imagesLoaded ? (
        <Slider {...sliderSettings}>
          {imagesToDisplay.map((imageObj, index) => (
            <div
              className="image-container"
              onClick={() => openLightbox(index)}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  openLightbox(index);
                }
              }}
              role="button"
              tabIndex={0}
              key={index}
            >
              <img
                className="card-img-top"
                src={imageObj.url}
                alt={imageObj.title}
              />
            </div>
          ))}
        </Slider>
      ) : loadingError ? (
        <div>Error loading images. Please try again later.</div>
      ) : (
        <div>Loading images...</div>
      )}
      {isOpen && (
        <Lightbox
          mainSrc={flattenedImages[photoIndex].url}
          nextSrc={
            flattenedImages[(photoIndex + 1) % flattenedImages.length].url
          }
          prevSrc={
            flattenedImages[
              (photoIndex + flattenedImages.length - 1) % flattenedImages.length
            ].url
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + flattenedImages.length - 1) % flattenedImages.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % flattenedImages.length)
          }
        />
      )}
    </div>
  );
}

export default Gallery;
