import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCompany } from "../../api/companiesApi";

export const companyFetch = createAsyncThunk("company/fetch", getCompany);

export const initialState = {
  company: null,
  categories: null,
  isLoading: false,
  loaded: false,
  error: null,
};

export const companySlice = createSlice({
	name: 'company',
	initialState,
	extraReducers: builder => {
		builder.addCase(companyFetch.pending, state => {
			state.isLoading = true;
		});
		
		builder.addCase(companyFetch.fulfilled, (state, action) => {
			state.isLoading = false;
			state.loaded = true;
			state.company = action.payload.data.company;
			state.categories = action.payload.data.company.categories;
		});
		
		builder.addCase(companyFetch.rejected, (state, action) => {
			state.isLoading = false;
			state.loaded = false;
			state.error = action.payload;
		});		
	}
});


const companyReducer = companySlice.reducer;
export default companyReducer;
