import { useState } from "react";
import { Fragment } from "react";
import Loader from "./globals/elements/loader";
import RootLayout from "./layouts/root-layout";
import ToggleButton from "./app/components/ToggleButton";
import ScrollButton from "./app/components/ScrollTop/ScrollButton";
import { Content } from "./app/components/ScrollTop/Styles";
function App() {  
  const [isLoading, setLoading] = useState(true);
  setTimeout(() => {
    setLoading(false);
  }, 3000);
  return (
    <>
      {isLoading && <Loader />}
      <RootLayout />
      <ToggleButton />
      <Fragment>
      <Content />
      <ScrollButton />
      </Fragment>
    </>
  );
}
export default App;
