import { configureStore } from "@reduxjs/toolkit";
import settingsReducer from "./settingsSlice";
import homeReducer from "./homeSlice";
import servicesReducer from "./servicesSlice";
import pagesReducer from "./pagesSlice";
import aboutReducer from "./aboutSlice";
import singleServiceReducer from "./singleServiceSlice";
import cartReducer from "./cartSlice";
import companiesReducer from "./companiesSlice";
import companyReducer from "./singleCompanySlice";
import categoryReducer from "./singleCategoryProductsSlice";
import singleProductReducer from "./singleProduct";
import countriesReducer from "./countrySlice";

export const store = configureStore({
  reducer: {
    homeData: homeReducer,
    settings: settingsReducer,
    // products: productsReducer,
    product: singleProductReducer,
    services: servicesReducer,
    pages: pagesReducer,
    about: aboutReducer,
    service: singleServiceReducer,
    cart: cartReducer,
    companies: companiesReducer,
    company: companyReducer,
    category: categoryReducer,
    // gallery: galleryReducer,
    countries: countriesReducer,
  },
});
