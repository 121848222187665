function ShopCheckoutPage() {
  return (
    <>
      {/* Checkout */}
      <div className="section-full p-t80 p-b50">
        <div className="container woo-entry">
          <div className="section-content">
            <div className="row">
              <div className="col-lg-8 col-md-12 m-b30">
                <div className="bg-gray p-a30 m-b30 radius-10">
                  <div className="section-head">
                    <h2>Billing Information</h2>
                    <div className="mt-separator-outer">
                      <div className="mt-separator site-bg-primary" />
                    </div>
                  </div>
                  <div className="mt-box bdr-4 bdr-gray-light">
                    <form>
                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="firstName">First Name</label>
                            <input
                              type="text"
                              id="firstName"
                              className="form-control"
                              placeholder="First Name"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="lastName">Last Name</label>
                            <input
                              type="text"
                              id="lastName"
                              className="form-control"
                              placeholder="Last Name"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="companyName">Company Name</label>
                        <input
                          type="text"
                          id="companyName"
                          className="form-control"
                          placeholder="Company Name"
                        />
                      </div>

                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="phone">Phone</label>
                            <input
                              type="text"
                              id="phone"
                              className="form-control"
                              placeholder="Enter Phone Number"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="email">Email Address</label>
                            <input
                              type="email"
                              id="email"
                              className="form-control"
                              placeholder="Enter Email"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="country">Country</label>
                            <select
                              id="country"
                              className="form-control form-select"
                            >
                              <option>USA</option>
                              <option>China</option>
                              <option>India</option>
                              <option>Australia</option>
                              <option>Japan</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="stateCity">State / City</label>
                            <select
                              id="stateCity"
                              className="form-control form-select"
                            >
                              <option>Los Angeles</option>
                              <option>Chicago</option>
                              <option>Phoenix</option>
                              <option>San Diego</option>
                              <option>Dallas</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="town">Town</label>
                            <select
                              id="town"
                              className="form-control form-select"
                            >
                              <option>Columbia</option>
                              <option>Berkeley</option>
                              <option>The Woodlands</option>
                              <option>Plano</option>
                              <option>Boulder</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="postalCode">EZIP / Post Code</label>
                            <input
                              type="text"
                              id="postalCode"
                              className="form-control"
                              placeholder="EZIP / Post Code"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="address1">Address</label>
                        <input
                          type="text"
                          id="address1"
                          className="form-control m-b30"
                          placeholder="Address 1"
                        />
                        <input
                          type="text"
                          id="address2"
                          className="form-control"
                          placeholder="Address 2"
                        />
                      </div>

                      <div className="form-group form-inline">
                        <div className="radio">
                          <input
                            id="checkmeout1"
                            name="Public"
                            value="checkmeout"
                            type="checkbox"
                          />
                          <label htmlFor="checkmeout1">
                            Ship to the same address
                          </label>
                        </div>
                      </div>

                      <button type="submit" className="site-button-secondary">
                        Save and Deliver Here
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="site-bg-primary p-a30 m-b30 radius-10">
                  <div className="section-head text-white">
                    <h2>Your Order</h2>
                    <div className="mt-separator-outer">
                      <div className="mt-separator bg-white" />
                    </div>
                  </div>
                  <div className="mt-box your-order-list bdr-4 bdr-gray-light text-white">
                    <ul>
                      <li>
                        Your Item Name
                        <strong className="pull-right">$219.00</strong>
                      </li>
                      <li>
                        <b> Cart Subtotal</b>
                        <strong className="pull-right">$219.00</strong>
                      </li>
                      <li>
                        <b> Shipping</b>
                        <strong className="pull-right">$31.00</strong>
                      </li>
                      <li>
                        <b> Cart Total</b>
                        <strong className="pull-right">$250.00</strong>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ShopCheckoutPage;
