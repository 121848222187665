import { Link, NavLink, useParams } from "react-router-dom";
import FastImage from "../../../globals/elements/fastimg";
import { route, publicUrlFor, loadScript } from "../../../globals/constants";
import { Fragment, useEffect } from "react";
import _data from "../../../globals/data/data.json";
import { useDispatch, useSelector } from "react-redux";
import { servicesFetch } from "../../../store/servicesSlice";
import { useTranslation } from "react-i18next";
import SectionBanner from "../../sections/common/banner/banner";
import Loader from "../Loader";

function ServicesPage() {
  const { t } = useTranslation();
  const { lang } = useParams();
  const locale = lang;
  const dir = lang === "en" ? "ltr" : "ltr";
  const dispatch = useDispatch();
  const data = useSelector((state) => state.services);

  const { isLoading, loaded, error, processSection, services, processes } =
    data;

  console.log({ data });

  useEffect(() => {
    dispatch(servicesFetch({ locale }));
  }, []);

  useEffect(() => {
    loadScript("js/custom.js");
  });

  if (isLoading) {
    return <Loader />;
  }

  if (error || !processSection || !services || !processes) {
    return <h1>Error fetching data!</h1>;
  }

  const currentpath = {
    crumb: t("our_services"),
    title: t("our_services"),
  };

  return (
    <Fragment>
      <SectionBanner data={currentpath} />

      <div className="section-full  bg-gray p-t80 p-b50">
        <div className="container">
          {/* TITLE START*/}
          <div className="section-head text-center">
            {loaded && <h2 data-title={'Processes'}>{processSection.title}</h2>}
            <div className="mt-separator-outer">
              <div className="mt-separator site-bg-primary" />
            </div>
          </div>
          {/* TITLE END*/}
          <div className="section-content">
            <div className="row d-flex justify-content-center">
              {loaded &&
                processes.map((item, index) => {
                  return (
                    <div key={index} className="col-lg-4 col-md-6">
                      <div className="mt-icon-box-wraper m-b30  p-a30 center bg-white">
                        <div className="mt-icon-box-sm site-bg-primary m-b20 radius-10">
                          <span className="icon-cell text-white">
                            <FastImage proImage={item.image} />
                          </span>
                        </div>
                        <div className="icon-content">
                          <h4 className="mt-tilte">{item.title}</h4>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      {/* Our Services */}
      <div className="section-full bg-white p-t80 p-b50">
        <div className="container">
          {/* TITLE START*/}
          <div className="section-head text-center">
            <h2 data-title="Services">{t("our_services")}</h2>
            <div className="mt-separator-outer">
              <div className="mt-separator site-bg-primary" />
            </div>
          </div>
          {/* TITLE END*/}
          <div className="mfp-gallery clearfix">
            <div className="row d-flex justify-content-center">
              {loaded &&
                services.map((item, index) => {
                  return (
                    <div
                      key={index}
                      dir={dir}
                      className="col-lg-4 col-md-6 m-b30"
                    >
                      <Link to={`/${lang}/service/detail/${item.id}`}>
                        <div className="mt-box bg-white bdr-1 bdr-solid bdr-gray-light mt-service-1">
                          <div className="mt-thum-bx mt-img-effect">
                            <FastImage
                              src={item.image}
                              proImage={item.image}
                              style={{  objectFit: 'cover' }}
                              alt={item.title}
                            />
                            
                          </div>
                          <div className="mt-info p-a20">
                            <h4 className="m-t0">{item.title}</h4>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.description.substring(0, 80)+'...',
                              }}
                              style={{color: '#1a1a1a'}}
                            />
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      
    </Fragment>
  );
}
export default ServicesPage;
