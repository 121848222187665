import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import FastImage from "../../../../globals/elements/fastimg";
import { t } from "i18next";
import { searchFilter } from "../../../../api/servicesApi";
import { Row } from "react-bootstrap";
import SectionBanner from "../../common/banner/banner";

function FilteredTrips() {
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filteredTrips, setFilteredTrips] = useState([]);
  const location = useLocation();
  const { lang } = useParams();
  const locale = lang;
  const { persons } = location.state || {};

  const data = {
    travelfrom_id: 1,
    travelto_id: 1,
    persons: persons || [],
  };

  console.log("Received persons:", persons); // Debugging log

  const displayFilteredTrips = () => {
    return (
      loaded &&
      filteredTrips?.map((item, index) => {
        return (
          <div
          key={item.id}
          // style={{ maxWidth: "25rem" }}
          className="col-lg-4 col-md-6 col-sm-6 m-b30"
        >
          <div className="mt-box mt-product-box bdr-5 bdr-solid border border-white shadow shaodw bg-white">
            <div className="mt-thum-bx mt-img-overlay4 mt-img-effect zoom">
              <FastImage
                proImage={item.image && item.image}
                src={item.image}
                alt={item.title}
                style={{ height: 350 }}
              />
            </div>
            <div className="mt-info  text-center">
              <div className="p-a10">
                <h4 title={item.title.substring(0, 30)} className="mt-title">
                  {" "}
                  <Link to={`/${lang}/service/detail/${item.id}`}>
                    {item.title.substring(0, 30)}
                  </Link>
                </h4>
                <p
                  className="fs-6"
                  style={{ height: 80 }}
                  dangerouslySetInnerHTML={{
                    __html: item.description.substring(0, 100) + "...",
                  }}
                />
                <div className="p-tb15">
                  <Link
                    className="site-button"
                    to={`/${lang}/service/detail/${item.id}`}
                  >
                    {t("trip_detail")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        );
      })
    );
  };

  useEffect(() => {
    if (persons) {
      searchFilter({ locale, values: data })
        .then((res) => {
          setLoaded(true);
          setFilteredTrips(res.data.services);
          setLoading(false);
        })
        .catch((err) => {
          console.log({ err });
          setLoading(false);
        });
    }
  }, [persons]);

  const currentpath = {
    crumb: t("our_trips"),
    title: t("our_trips"),
  };

  return (
    <Fragment>
      <SectionBanner data={currentpath} />

      <div className="container-fluid bg-gray">
        <div className="setcion-content">
          <div className=" text-center ">
            <h2 className="p-30 text-black">{t("trips")} </h2>
            <div className="mt-separator-outer">
              <div className="mt-separator site-bg-primary mb-5" />
              <div className="row d-flex justify-content-center">
                <div className="col-sm-12 col-md-9 col-lg-9 mt-5">
                  <div className="row">
                    {loaded ? (
                      <Row>
                        {displayFilteredTrips().length > 0 ? (
                          displayFilteredTrips()
                        ) : (
                          <h3 className="text-center">{t("no_trips")}</h3>
                        )}
                      </Row>
                    ) : (
                      <h3 className="text-center">{t("loading")}</h3>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default FilteredTrips;
