// Functions import
import { Fragment, useEffect, useState } from "react";
import { loadScript, route, publicUrlFor } from "../../../globals/constants";
import FastImage from "../../../globals/elements/fastimg";
import CountUp from "react-countup";
import { Link, NavLink, useParams } from "react-router-dom";
import SectionHome1Testimonials from "../../sections/home/home1/section-home1-testimonials";
import _data from "../../../globals/data/data.json";
import { getCounters } from "../../../api/countersApi";
import { useDispatch, useSelector } from "react-redux";
import { homeFetch } from "../../../store/homeSlice";
import { postContactUs } from "../../../api/contactusApi";
import { SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import { useTranslation } from "react-i18next";
import HomeSlider from "../../sections/home/home1/HomeSlider";
import Loader from "../Loader";
import ChooseusSlider from "../../sections/home/home1/ChooseusSlider";
import { pagesFetch } from "../../../store/pagesSlice";
import Error from "../Error";
import AlertToast from "../Alert";
import PhoneInput from "react-phone-input-2";
import Search from "../../sections/home/home1/Search";
import Gallery from "../../sections/home/home1/Gallery";
import SectionHome1Trips from "../../sections/home/home1/section-home1-trips";
import { countriesFetch } from "../../../store/countrySlice";
import FilteredTrips from "../../sections/home/home1/FilteredTrips";

import "react-phone-input-2/lib/style.css";
import SectionBanner from "../../sections/common/banner/banner";

function IndexPage() {
  const { lang } = useParams();
  const { t } = useTranslation();
  const dir = lang === "en" ? "ltr" : "ltr";
  const locale = lang;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState({});
  const [disabled, setDisabled] = useState(false);

  const [values, setValues] = useState({
    email: "",
    name: "",
    message: "",
  });

  const { email, name, message } = values;

  const { homeData, pages } = useSelector((state) => state);

  useEffect(() => {
    dispatch(homeFetch({ locale }));
    dispatch(pagesFetch({ locale }));
    dispatch(countriesFetch({ locale }));
  }, []);

  const gallery = homeData.galleries;

  const { isLoading, loaded } = homeData;
  const errorFetch = homeData.error;

  // console.log(to, from);

  // console.log(filteredTripsVisible);
  useEffect(() => {
    loadScript("js/custom.js");
  });

  if (isLoading) {
    return <Loader />;
  }

  if (errorFetch) {
    return <h1>Fetching data error!</h1>;
  }

  const handleClose = () => {
    setOpen(false);
    setError(false);
  };

  const currentpath = {
    crumb: t("portfolios"),
    title: t("portfolios"),
  };

  return (
    <div dir={dir}>
      <AlertToast open={open} message={text} handleClose={handleClose} />
      <SectionBanner data={currentpath} />
      {/* Gallery */}
      <div className="mt-5 mb-5">
        {loaded && <Gallery gallery={gallery} showAll={true} />}
      </div>
    </div>
  );
}
export default IndexPage;
