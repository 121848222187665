import { api } from "./base";

export const getCompanies = async ({ locale }) => {
  api.defaults.headers = { locale };
  const res = await api.get("/companies");
  return res.data;
};

export const getCompany = async ({ locale, companyId }) => {
  api.defaults.headers = { locale };
  const res = await api.get(`/company/${companyId}`);
  return res.data;
};
