import { api } from "./base";

export const getCountries = async ({ locale }) => {
  api.defaults.headers = { locale };
  const res = await api.get("/countries");
  return res.data;
};

export const getCountry = async ({ locale, countryId }) => {
  api.defaults.headers = { locale };
  const res = await api.get(`/country/${countryId}`);
  return res.data;
};
