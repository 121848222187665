import { useEffect, useState } from "react";
import SectionBanner from "../../sections/common/banner/banner";
import { t } from "i18next";
const VideosPage = () => {
    const [videos, setVideos] = useState([]);
    
    useEffect(() => {
      fetch('https://yousab-tech.com/EgyptTourismApi/public/api/videos')
          .then((response) => response.json())
          .then((data) => {
              const formattedVideos = data.data.videos.map(video => ({
                  ...video,
                  link_video: video.link_video.replace("watch?v=", "embed/")
              }));
              setVideos(formattedVideos);
          })
          .catch((error) => {
              console.error('Error fetching videos:', error);
          });
  }, []);
  const currentpath = {
    crumb: t("videos"),
    title: t("Videos"),
  };

    return (
        <div>
            
            <SectionBanner data={currentpath} />
            <div style={{ height: "50px" }}></div>
            <div className="container">
            {videos.map((video) => (
                <iframe 
                    style={{borderRadius:"10px"}}
                    key={video.id}
                    src={video.link_video} 
                    width="275" 
                    height="160" 
                    allowFullScreen 
                    title={`Video ${video.id}`}>
                    
                </iframe>
            ))}
           </div>
        </div>
    );
}

export default VideosPage;
