import React, { Fragment, useEffect, useState } from "react";
import SectionBanner from "../../../sections/common/banner/banner";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useParams } from "react-router-dom";
import { JSONTree } from "react-json-tree";
import Loader from "../../../../globals/elements/loader";
import FastImage from "../../../../globals/elements/fastimg";
import { Row } from "react-bootstrap";
import { categoryFetch } from "../../../../store/singleCategoryProductsSlice";
import { route } from "../../../../globals/constants";
import { addToCart } from "../../../../store/cartSlice";
import { getCategory } from "../../../../api/categoriesApi";
import toast, { Toaster } from "react-hot-toast";

const CategoryPage = () => {
  const { lang, categoryId } = useParams();
  const dir = lang === "en" ? "ltr" : "ltr";
  const locale = lang;
  const dispatch = useDispatch();

  const [loaded, setLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getCategory({ locale, categoryId })
      .then((res) => {
        setProducts(res.data.category.products);
        setLoaded(true);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log({ err });
      });
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  const notify = () => toast(t("added_to_cart"));

  const handleAddToCart = (product) => {
    dispatch(addToCart({ product, qty: 1 }));
    notify();
  };

  const displayProducts = () => {
    return (
      loaded &&
      products?.map((product) => {
        return (
          <div
          key={product.id}
          // style={{ maxWidth: "25rem" }}
          className="col-lg-4 col-md-6 col-sm-6 m-b30"
        >
          <div className="mt-box mt-product-box bdr-5 bdr-solid border border-white shadow shaodw bg-white">
            <div className="mt-thum-bx mt-img-overlay4 mt-img-effect zoom">
              <FastImage
                proImage={product.image && product.image}
                src={product.image}
                alt={product.title}
                style={{ height: 350 }}
              />
            </div>
            <div className="mt-info  text-center">
              <div className="p-a10">
                <h4 title={product.title.substring(0, 30)} className="mt-title">
                  {" "}
                  <Link to={`/${lang}/service/detail/${product.id}`}>
                    {product.title.substring(0, 30)}
                  </Link>
                </h4>
                <p
                  className="fs-6"
                  style={{ height: 80 }}
                  dangerouslySetInnerHTML={{
                    __html: product.description.substring(0, 100) + "...",
                  }}
                />
                <div className="p-tb15">
                  <Link
                    className="site-button"
                    to={`/${lang}/service/detail/${product.id}`}
                  >
                    {t("trip_detail")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        );
      })
    );
  };

  const currentpath = {
    crumb: t("products"),
    title: t("products"),
  };

  return (
    <Fragment>
      <SectionBanner data={currentpath} />
      <Toaster
        toastOptions={{
          style: {
            color: "#fff",
            background: "var(--color-main)",
          },
        }}
      />
      {/* <JSONTree data={products} /> */}
      {/* TITLE START */}
      <div dir={dir} className="section-full p-t80">
        <div className="container">
          <div className="section-content"></div>
          <div className="section-head text-center">
            <h2 data-title={t("products")}>{t("products")}</h2>
            <div className="mt-separator-outer m-b30">
              <div className="mt-separator site-bg-primary" />
            </div>
          </div>
          {/* TITLE END */}
          {loaded ? (
            <Row>{displayProducts()}</Row>
          ) : (
            <h3 className="text-center">{t("no_products")}</h3>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default CategoryPage;
