import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAbout } from "../../api/aboutApi";

export const aboutFetch = createAsyncThunk("about/fetch", getAbout);

const initialState = {
  isLoading: false,
  loaded: false,
  error: null,
  aboutSection: null,
  visionSection: null,
  missionSection: null,
};

export const aboutSlice = createSlice({
  name: "about",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(aboutFetch.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(aboutFetch.fulfilled, (state, action) => {
      state.isLoading = false;
      state.loaded = true;
      state.aboutSection = action.payload.data["about-section"];
      state.visionSection = action.payload.data["vision-section"];
      state.missionSection = action.payload.data["mission-section"];
    });
    builder.addCase(aboutFetch.rejected, (state, action) => {
      state.loaded = false;
      state.error = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = aboutSlice.actions;
const aboutReducer = aboutSlice.reducer;
export default aboutReducer;
