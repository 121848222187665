import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCountries } from "../../api/countryApi";

export const countriesFetch = createAsyncThunk("countries/fetch", getCountries);

const initialState = {
  value: null,
  isLoading: false,
  loaded: false,
  error: null,
};

export const countriesSlice = createSlice({
  name: "countries",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(countriesFetch.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(countriesFetch.fulfilled, (state, action) => {
      state.isLoading = false;
      state.loaded = true;
      state.value = action.payload.data;
    });
    builder.addCase(countriesFetch.rejected, (state, action) => {
      state.loaded = false;
      state.error = action.error.message;
    });
  },
});

export const {} = countriesSlice.actions;
const countriesReducer = countriesSlice.reducer;
export default countriesReducer;
