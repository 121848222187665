/* eslint-disable react/prop-types */
import { publicUrlFor } from "../constants";

function FastImage(props) {
  return (
    <>
      <img
        {...props}
        src={props.proImage ? props.proImage : publicUrlFor(props.src)}
        alt={props.alt}
      />
    </>
  );
}
export default FastImage;
