import { api } from "./base";

export const getCategories = async ({ locale }) => {
  api.defaults.headers = { locale };
  const res = await api.get("/categories");
  return res.data;
};

export const getCategory = async ({ locale, categoryId }) => {
  api.defaults.headers = { locale };
  const res = await api.get(`/category/${categoryId}`);
  return res.data;
};

export const getSubCategory = async ({ locale, categoryId }) => {
  api.defaults.headers = { locale };
  const res = await api.get(`/subcategory/${categoryId}`);
  return res.data;
};
