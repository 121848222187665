import { createSlice } from "@reduxjs/toolkit";
import { calcTotal } from "../../helpers/settings";

const initialState = {
  items: [],
  totalQty: 0,
  totalPrice: 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      let product = action.payload.product;
      const foundProduct = state.items.find((item) => item.id === product.id);
      let newArr;
      if (foundProduct) {
        newArr = state.items.map((item) => {
          if (item.id === product.id) {
            item.qty += action.payload.qty;
            item["totalPrice"] = item.qty * item.price;
          }
          return item;
        });
        state.totalPrice = calcTotal(newArr);
        state.items = newArr;
        localStorage.setItem('cart', JSON.stringify(state));
      } else {
        product["qty"] = action.payload.qty;
        state.totalQty++;
        product["totalPrice"] = product["qty"] * product.price;
        state.totalPrice += product["totalPrice"];
        state.items = [...state.items, product];
        localStorage.setItem('cart', JSON.stringify(state));
      }
    },

    saveCart: (state, action) => {
      if(localStorage.getItem('cart')){
        let cart = JSON.parse(localStorage.getItem('cart'));
        state.items = cart.items;
        state.totalPrice = cart.totalPrice;
        state.totalQty = cart.totalQty;
      }
    },

    addQty: (state, action) => {
      const newArr = state.items.map((item) => {
        if (item.id === action.payload) {
          item["qty"]++;
          item["totalPrice"] = item.qty * item.price;
        }
        return item;
      });
      state.totalPrice = calcTotal(newArr);
      state.items = newArr;
      localStorage.setItem('cart', JSON.stringify(state));
    },

    removeItem: (state, action) => {
      const newArr = state.items.filter((item) => item.id !== action.payload);
      state.totalPrice = calcTotal(newArr);
      state.totalQty--;
      state.items = newArr;
      localStorage.setItem('cart', JSON.stringify(state));
    },

    decreaseQty: (state, action) => {
      const newArr = state.items.map((item) => {
        if (item.id === action.payload && item.qty > 1) {
          item["qty"]--;
          item["totalPrice"] = item.qty * item.price;
        }
        return item;
      });
      state.totalPrice = calcTotal(newArr);
      state.items = newArr;
      localStorage.setItem('cart', JSON.stringify(state));
    },

    emptyCart: (state, action) => {
      state.items = [];
      state.totalQty = 0;
      state.totalPrice = 0;
      localStorage.removeItem('cart');
    }
  },
});

export const { addToCart, saveCart, removeItem, addQty, decreaseQty, setTotalPriceCart, emptyCart } =
  cartSlice.actions;
const cartReducer = cartSlice.reducer;
export default cartReducer;
