import React, { Fragment, useEffect } from "react";
import SectionBanner from "../../../sections/common/banner/banner";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { companiesFetch } from "../../../../store/companiesSlice";
import { NavLink, useParams } from "react-router-dom";
import { JSONTree } from "react-json-tree";
import Loader from "../../../../globals/elements/loader";
import FastImage from "../../../../globals/elements/fastimg";
import { route } from "../../../../globals/constants";
import { Col, Row } from "react-bootstrap";

const CompaniesPage = () => {
  const { lang } = useParams();
	const dir = lang === 'en' ? 'ltr' : 'rtl';
  const locale = lang;
  const dispatch = useDispatch();
  const companiesData = useSelector((state) => state.companies);
  const { isLoading, loaded, error, value } = companiesData;
  const companies = value.companys;

  useEffect(() => {
    dispatch(companiesFetch({ locale }));
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <h1>Error fetching data!</h1>;
  }

  const currentpath = {
    crumb: t("companies"),
    title: t("companies"),
  };

  const displayCompanies = () => {
    return (
      loaded &&
      companies.map((company) => {
        return (
          <Col
            key={company.id}
            className="col-lg-4 col-md-6 col-xs-100pc m-b30"
          >
            <div
              style={{ height: 300, width: 300 }}
              className="item overflow-hide"
            >
              <div className="mt-box">
                <div
                  style={{ height: "100%" }}
                  className="mt-img-effect overlay-2"
                >
                  <FastImage
                    src={company.image}
                    proImage={company.image}
                    alt={company.title}
                    style={{ height: 300 }}
                  />
                  <div className="overlay-2-bg bg-black" />
                  <div className="overlay-2-content">
                    <div className="p-a30 p-b20 d-flex flex-column align-items-center justify-content-center">
                      <h4 className="m-t0 m-b15 site-text-primary">
                        {company.title}
                      </h4>
                      <div
                        className="m-b20 text-white"
                        style={{
                          fontSize: "0.7rem",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: company.description,
                        }}
                      />

                      <NavLink to={`/${lang}/company/${company.id}`} className="site-button">
                        {t('show_more')}
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        );
      })
    );
  };

  return (
    <Fragment>
      <SectionBanner data={currentpath} />
      {/* <JSONTree data={companies} /> */}
      {/* TITLE START */}
      <div dir={dir} className="section-full p-t80">
        <div className="container">
          <div className="section-content"></div>
          <div className="section-head text-center">
            <h2 data-title={"Companies"}>{t("companies")}</h2>
            <div className="mt-separator-outer m-b30">
              <div className="mt-separator site-bg-primary" />
            </div>
          </div>
          {/* TITLE END */}
          <Row>{displayCompanies()}</Row>
        </div>
      </div>
    </Fragment>
  );
};

export default CompaniesPage;
