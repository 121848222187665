import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getProduct } from "../../api/productsApi";

export const productFetch = createAsyncThunk("service/fetch", getProduct);

export const initialState = {
  product: null,
  images: null,
  isLoading: false,
  loaded: false,
  error: null,
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(productFetch.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(productFetch.fulfilled, (state, action) => {
      state.isLoading = false;
      state.loaded = true;
      state.product = action.payload.data.product;
      // state.images = action.payload.data.product.images;
    });
    builder.addCase(productFetch.rejected, (state, action) => {
      state.loaded = false;
      state.error = action.payload;
    });
  },
});

const singleProductReducer = productSlice.reducer;
export default singleProductReducer;
