/* eslint-disable react/prop-types */
import { Alert, Snackbar } from "@mui/material";
import { t } from "i18next";
import React from "react";

const Error = ({ error, handleClose, errorsShow }) => {
  if (error) {
    // Create an array of error properties
    const errorProperties = Object.keys(error);

    // Loop through the error properties and display them
    const errorDetails = errorProperties.map((property, index) => (
      <Snackbar
        key={index}
        open={errorsShow}
        autoHideDuration={10000}
        onClose={handleClose}
      >
        <Alert
          sx={{ mb: 2 * index * 4 }}
          onClose={handleClose}
          variant="filled"
          severity="error"
        >
          <strong>{property}:</strong> {error[property]}
        </Alert>
      </Snackbar>
    ));

    return (
      <div>
        {/* <h2>{t('errors')}:</h2> */}
        <ul>{errorDetails}</ul>
      </div>
    );
  }
};

export default Error;
