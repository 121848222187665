import { Fragment, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import SectionHeader2 from "../app/sections/common/header/header2";
import SectionFooter1 from "../app/sections/common/footer/footer1";
import { Helmet } from "react-helmet-async";
import { getSettings } from "../api/settingsApi";
import { setSettings } from "../store/settingsSlice";
import { useDispatch, useSelector } from "react-redux";

const Layout = () => {
  const { lang } = useParams();
  const locale = lang;
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.value);

  useEffect(() => {
    getSettings({ locale })
      .then((res) => {
        dispatch(setSettings(res.data.settings));
      })
      .catch((err) => {
        console.log({ err });
      });
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>{settings?.title}</title>
        <link rel="canonical" href="https://www.tacobell.com/" />
        <link
          id="favicon"
          rel="icon"
          href={settings?.tab}
          type="image/x-icon"
        />
        <meta charset="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="" />
        <meta name="author" content="" />
        <meta name="robots" content="" />
        <meta name="description" content={settings?.title} />

        <link rel="icon" href={settings?.tab} type="image/x-icon" />
        <link rel="shortcut icon" type="image/x-icon" href={settings?.tab} />
        <link rel="apple-touch-icon" href={settings?.tab} />
      </Helmet>
      <SectionHeader2 />
      <Outlet />
      <SectionFooter1 theme="footer-dark" />
    </Fragment>
  );
};

export default Layout;
