/* eslint-disable react/prop-types */
import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const SwiperWrapper = ({ children, ...rest }) => {
  return <Swiper {...rest}>{children}</Swiper>;
};

export default SwiperWrapper;
