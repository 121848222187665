import React from "react";
import { Audio } from "react-loader-spinner";
import styles from './Loader.module.css';
const Loader = () => {
  return (
    <div className={`${styles.container} d-flex align-items-center justify-content-center`}>
      <Audio
        height="80"
        width="80"
        radius="9"
        color="#153C6E"
        ariaLabel="three-dots-loading"
        wrapperStyle
        wrapperClass
      />
    </div>
  );
};

export default Loader;
