import i18next from "i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { defaultLang } from "./helpers/settings";
import translationEN from "./locales/en/translation.json";
import translationAR from "./locales/ar/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationAR,
  },
};

i18next.use(LanguageDetector).use(initReactI18next).init({
  resources,
  // The default language
  fallbackLng: ['ar', 'en'],
  lng: defaultLang,
  supportedLngs: ['ar', 'en'],
  defaultLang
});
