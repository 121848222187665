import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPages } from "../../api/pagesApi";

export const pagesFetch = createAsyncThunk("pages/fetch", getPages);

const initialState = {
  value: null,
  contactSection: null,
  isLoading: false,
  loaded: false,
  error: null,
};

export const pagesSlice = createSlice({
  name: "pages",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(pagesFetch.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(pagesFetch.fulfilled, (state, action) => {
      state.isLoading = false;
      state.loaded = true;
      const about = action.payload.data.pages.find(
        (page) => page.identifier === "about"
      );
      const contact = action.payload.data.pages.find(
        (page) => page.identifier === "contact"
      );

      if (about) {
        state.value = about;
      } 
      if (contact) {
        state.contactSection = contact;
      } 
    });
    builder.addCase(pagesFetch.rejected, (state, action) => {
      state.isLoading = false;
      state.loaded = false;
      state.error = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setpages } = pagesSlice.actions;
const pagesReducer = pagesSlice.reducer;
export default pagesReducer;
