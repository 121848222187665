import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsapp,
  faFacebookMessenger,
  faTelegram,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import "./ToggleButton.css";

const ToggleButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const settings = useSelector((state) => state.settings.value);

  const toggleOptions = () => {
    console.log("Here we show settings", settings.socials);
    setIsOpen(!isOpen);
  };

  return (
    <div className="toggle-button-container"  style={{ zIndex: 9999 }}>
      <div className={`options ${isOpen ? "open" : ""}`}>
        {settings && settings.socials && (
          <>
            <a
              href={`https://wa.me/${settings?.whatsapp[0].contact}`}
              target="_blank"
              rel="noopener noreferrer"
              className="option whatsapp"
            >
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
            <a
              href={`tel:${settings?.phones[0].contact}`}
              className="option call"
            >
              <FontAwesomeIcon icon={faPhone} />
            </a>
            <a
              href={`${settings?.socials[4]?.contact}`}
              target="_blank"
              rel="noopener noreferrer"
              className="option messenger"
            >
              <FontAwesomeIcon icon={faFacebookMessenger} />
            </a>
            <a
              href={`mailto:${settings?.emails[0].contact}`}
              className="option email"
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
            <a
              href={`${settings?.socials[0].contact}`}
              target="_blank"
              rel="noopener noreferrer"
              className="option telegram"
            >
              <FontAwesomeIcon icon={faTelegram} />
            </a>

            {/* <a
              href={`${settings?.socials[3]?.contact}`}
              target="_blank"
              rel="noopener noreferrer"
              className="option facebook"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a> */}
            <a
              href={`${settings?.socials[2].contact}`}
              target="_blank"
              rel="noopener noreferrer"
              className="option instagram"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </>
        )}
      </div>
      <button
        className="toggle-button"
        onClick={toggleOptions}
      >
        <span className="toggle-icon">
          <FontAwesomeIcon icon={faFacebookMessenger} />
        </span>
      </button>
    </div>
  );
};

export default ToggleButton;
