/* eslint-disable react/prop-types */
import React from "react";
import { Helmet } from "react-helmet-async";

const HelmetComponent = ({ title, description, image }) => {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      {/* <meta name='description' content={description} /> */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      {/* End standard metadata tags */}
    </Helmet>
  );
};

export default HelmetComponent;
